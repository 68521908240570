import styled from 'styled-components';
import { CommonTitle } from '../../styles/commonStyles';

const Point05Section = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 80px 50px;
  box-sizing: border-box;
  gap: 62px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-family: Pretendard;

  ${props => props.theme.media.laptop} {
    padding: 80px 50px;
    gap: 32px;
  }

  ${props => props.theme.media.mobile} {
    padding: 80px 16px;
  }
`;

const Text = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;

  p {
    margin: 0;
  }

  ${props => props.theme.media.laptop} {
    font-size: 18px;
    font-weight: 300;
  }
`;

const TopContent = styled.div`
  width: 729px;
  position: relative;
  font-size: 28px;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 500;
  color: #858585;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopContent1 = styled(CommonTitle)`
  ${props => props.theme.media.laptop} {
    font-size: 40px;
  }

  ${props => props.theme.media.mobile} {
    font-size: 32px;
  }
`;

const CompanyList = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 36px 26px;
  margin-top: 20px;

  ${props => props.theme.media.laptop} {
    width: 100%;
    gap: 24px 16px;
    justify-content: center;
  }

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`;

const CompanyIcon = styled.img`
  width: 254px;
  position: relative;
  height: 104px;
  object-fit: cover;
  opacity: 1;

  ${props => props.theme.media.laptop} {
    width: 200px;
    height: 82px;
  }

  ${props => props.theme.media.mobile} {
    width: 150px;
    height: auto;
  }
`;

function Point05() {
  const companies = [
    '/images/renewal/Company/partner-06.png', // KT
    '/images/renewal/Company/partner-05.png', // LG
    '/images/renewal/Company/partner-04.png', // 안산시
    '/images/renewal/Company/partner-03.png', // 포스코
    '/images/renewal/Company/partner-02.png', // GS
    '/images/renewal/Company/partner-01.png'  // 현대
  ];

  return (
    <Point05Section>
      <TopContent>Career</TopContent>
      <TopContent1>
        <span>
          <p>꿈꾸는 모든 곳에</p>
          <p>펴다가 있습니다</p>
        </span>
      </TopContent1>
      <Text>
        <p>펴다는 지금 파트너사와 긴밀한 협업을 통해 광고영역을 넘어 문화 트랜드를 선도하고,</p>
        <p>기업의 가치를 높히는 최고의 성과를 이끌어갑니다.</p>
      </Text>
      <CompanyList>
        {companies.map((src, index) => (
          <CompanyIcon key={index} src={src} alt={`Company ${index + 1}`} />
        ))}
      </CompanyList>
    </Point05Section>
  );
}

export default Point05; 
