import styled from 'styled-components';
import { useState } from "react";
import { isEmail, isPhone } from "../../assets/utils/regex";
import { openChannelTalk } from "../../utils/channelTalk";

const ContactSection = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 120px 50px 80px;
  background-color: #0f0e10;
  color: #fff;

  ${props => props.theme.media.laptop} {
    padding: 80px 50px;
  }

  ${props => props.theme.media.mobile} {
    padding: 16px 16px 80px;
  }
`;

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 60px;

  ${props => props.theme.media.laptop} {
    padding: 0 20px;
    gap: 40px;
  }

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 24px;

  ${props => props.theme.media.laptop} {
    font-size: 40px;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${props => props.theme.media.laptop} {
    gap: 8px;
  }
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: 500;
`;

const Input = styled.input`
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: transparent;
  color: #fff;
  font-size: 16px;

  ${props => props.theme.media.laptop} {
    padding: 12px;
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border-color: #3c75f1;
  }
`;

const TextArea = styled.textarea`
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: transparent;
  color: #fff;
  font-size: 16px;
  min-height: 200px;
  resize: vertical;

  ${props => props.theme.media.laptop} {
    min-height: 160px;
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border-color: #3c75f1;
  }
`;

const SubmitButton = styled.button`
  padding: 16px 32px;
  background: #3c75f1;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #2b5cd9;
  }

  ${props => props.theme.media.laptop} {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const ContactTitleSection = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 122px 50px 40px;
  box-sizing: border-box;
  gap: 24px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  font-family: Pretendard;
  background-color: #0f0e10;

  ${props => props.theme.media.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const TopContent = styled.div`
  width: 1280px;
  position: relative;
  font-size: 40px;
  font-weight: 600;
  display: flex;
  align-items: center;

  ${props => props.theme.media.laptop} {
    width: 100%;
    font-size: 32px;
    padding: 0 20px;
  }
`;

const TopContentText = styled.span`
  width: 100%;

  p {
    margin: 0;
  }

  .highlight {
    margin: 0;
    color: #3c75f1;
  }
`;

const Button = styled.div`
  width: 207px;
  border-radius: 152px;
  border: 1px solid #fff;
  box-sizing: border-box;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 22px;
  cursor: pointer;
`;

const ButtonText = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 600;
`;

const Description = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  color: #bcbcbc;

  ${props => props.theme.media.laptop} {
    padding: 0 20px;
    font-size: 16px;
    word-break: keep-all;
  }

  p {
    margin: 0;
  }
`;

// ContactPoint 공통 컴포넌트
const ContactPoint = styled.div`
  width: 272.1px;
  border-radius: 1000px;
  border: 0.7px solid #fff;
  box-sizing: border-box;
  height: 272.1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding || '0px'};
  gap: ${props => props.gap || '8px'};
  margin-left: ${props => props.isFirst ? '0' : '-24px'};

  ${props => props.theme.media.laptop} {
    width: calc(50% - 10px);
    height: auto;
    aspect-ratio: 1;
    margin-left: 0;
    padding: 0;
  }
`;

const ContactPointSection = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 50px 150px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-family: Pretendard;
  background-color: #0f0e10;

  ${props => props.theme.media.mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ImageGridParent = styled.div`
  width: 1016.4px;
  position: relative;
  height: 861.1px;

  ${props => props.theme.media.laptop} {
    width: 100%;
    height: auto;
    padding-bottom: 80px;
  }
`;

const ImageGrid = styled.img`
  position: absolute;
  top: 88px;
  left: 188px;
  width: 641px;
  height: 641px;
  overflow: hidden;
  opacity: 0.3;
  object-fit: cover;

  ${props => props.theme.media.laptop} {
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
  }
`;

const ImageDevice = styled.img`
  position: absolute;
  top: 50px;
  left: 345px;
  width: 280px;
  height: 684px;
  object-fit: cover;

  ${props => props.theme.media.laptop} {
    width: 200px;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ImageDim = styled.div`
  position: absolute;
  top: 0px;
  left: calc(50% - 419.01px);
  background: linear-gradient(180deg, rgba(15, 14, 16, 0), #0f0e10 76.42%);
  width: 839px;
  height: 807px;

  ${props => props.theme.media.laptop} {
    width: 100%;
    left: 0;
    height: 100%;
  }
`;

const ContactPointContainer = styled.div`
  position: absolute;
  top: 589px;
  left: calc(50% - 508.2px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  ${props => props.theme.media.laptop} {
    position: relative;
    top: auto;
    left: auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 0 20px;
    margin-top: 500px;
  }

  ${props => props.theme.media.mobile} {
    gap: 12px;
    padding: 0;
  }
`;

const TextKor = styled.div`
  position: relative;
  letter-spacing: -0.02em;
`;

const TextEng = styled.div`
  position: relative;
  font-size: 14px;
  letter-spacing: -0.02em;
`;

const SuccessModal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 250px;
    background-color: #202124;
    border-radius: 16px;
    border: 1px solid #3c75f1;
    color: #fff;
  }

  p {
    font-size: 20px;
    line-height: 1.4em;
    text-align: center;
  }

  button {
    padding: 17px 32px;
    margin-top: 30px;
    width: 200px;
    box-shadow: 0px 7.4px 17.76px rgba(0, 0, 0, 0.08);
    border-radius: 13.32px;
    border: 0;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    background: #3c75f1;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: #2b5cd9;
    }
  }
`;

const ErrMessage = styled.p`
  margin-top: 6px;
  font-size: 14px;
  color: #3c75f1;
`;

function Contact() {

  return (
    <>
      <ContactTitleSection>
        <TopContent>
          <TopContentText>
            <p>펴다의</p>
            <p className="highlight">크리에이티브 솔루션</p>
          </TopContentText>
        </TopContent>
        <SubmitButton className="channel-talk-button" onClick={openChannelTalk}>문의하기</SubmitButton>
        <Description>
          <p>펴다는 자체 개발한 우양산 공유 서비스와 Ad-tech 기반 DiD 광고를 결합한 '펴다 스테이션'을 통해,</p>
          <p>ESG 캠페인과 온·오프라인 통합 마케팅 커뮤니케이션, 그리고 차별화된 브랜드 경험을 제공합니다.</p>
        </Description>
      </ContactTitleSection>
      <ContactPointSection>
        <ImageGridParent>
          <ImageGrid src="/images/renewal/Contact/ImgGrid.png" alt="Grid" />
          <ImageDevice src="/images/renewal/Contact/ImgDevice.png" alt="" />
          <ImageDim />
          <ContactPointContainer>
            <ContactPoint isFirst>
              <TextKor>우양산 공유 서비스</TextKor>
              <TextEng>Sharing Service</TextEng>
            </ContactPoint>
            <ContactPoint padding="113px 86px">
              <TextKor>DiD 광고</TextKor>
              <TextEng>DiD Advertising</TextEng>
            </ContactPoint>
            <ContactPoint padding="113px 87px">
              <TextKor>ESG 캠페인</TextKor>
              <TextEng>ESG Campaign</TextEng>
            </ContactPoint>
            <ContactPoint padding="100px 65px" gap="11px">
              <TextKor>
                <p>온·오프라인 통합</p>
                <p>마케팅 커뮤니케이션</p>
              </TextKor>
              <TextEng>
                <p>Integrated</p>
                <p>Marketing Communication</p>
              </TextEng>
            </ContactPoint>
          </ContactPointContainer>
        </ImageGridParent>
      </ContactPointSection>
    </>
  );
}

export default Contact; 