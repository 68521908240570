import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import headerLogo from '../../assets/svgs/header-logo.svg';
import MobileHeader from './MobileHeader';

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${props => props.isScrolled ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.21)'};
  transition: background-color 0.3s ease;
  height: 64px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-family: Pretendard;

  ${props => props.theme.media.mobile} {
    display: none;
  }
`;

const HeaderContent = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Logo = styled.img`
  width: 46px;
  height: 24px;
`;

const MenuList = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 22px;
  opacity: ${props => props.isActive ? 1 : 0.5};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const MenuText = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 600;
`;

const ContactButton = styled.div`
  width: fit-content;
  border-radius: 4px;
  background-color: #0d44ba;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 22px;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #0a3691;
  }
`;

const MenuLink = styled(Link)`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 600;
  text-decoration: none;
  color: inherit;
`;

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const handleDownload = () => {
    const fileUrl = process.env.PUBLIC_URL + '/files/펴다회사소개서_v.02.pdf';
    
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', '펴다회사소개서_v.02.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <MobileHeader onMenuClick={() => {/* 메뉴 클릭 핸들러 */}} />
      <HeaderContainer isScrolled={isScrolled}>
        <HeaderContent>
          <Logo src={headerLogo} alt="펴다 로고" onClick={() => {
            window.location.href = '/';
          }} />
          <MenuList>
            <MenuItem isActive={location.pathname === '/'}>
              <MenuLink to="/">회사소개</MenuLink>
            </MenuItem>
            <MenuItem isActive={location.pathname === '/contact'}>
              <MenuLink to="/contact">도입문의</MenuLink>
            </MenuItem>
            <MenuItem isActive={false}>
              <MenuLink as="div" onClick={handleDownload} style={{ cursor: 'pointer' }}>
                서비스 소개서
              </MenuLink>
            </MenuItem>
          </MenuList>
          <ContactButton className="channel-talk-button">
            Contact Us
          </ContactButton>
        </HeaderContent>
      </HeaderContainer>
    </>
  );
}

export default Header;
