import styled from 'styled-components';
import MainVideo from '../../components/MainVideo/MainVideo';
import ServiceCopy from '../../components/ServiceCopy/ServiceCopy';
import Doing from '../../components/Doing/Doing';
import Point01 from '../../components/Point01/Point01';
import Point02 from '../../components/Point02/Point02';
import Point03 from '../../components/Point03/Point03';
import Point04 from '../../components/Point04/Point04';
import Point05 from '../../components/Point05/Point05';
import Point06 from '../../components/Point06/Point06';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Routes, Route, useLocation } from 'react-router-dom';
import Contact from '../Contact/Contact';
import PrivacyPolicy from '../Policy/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from '../Policy/TermsOfService/TermsOfService';
import LocationTerms from '../Policy/LocationTerms/LocationTerms';
import { useEffect } from 'react';
import { showChannelButton, hideChannelButton } from '../../utils/channelTalk';

const Home = () => {
  return (
    <MainContainer>
      <MainVideo />
      <ServiceCopy />
      <Doing />
      <Point01 />
      <Point02 />
      <Point03 />
      <Point04 />
      <Point05 />
      <Point06 />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
`;

function Main() {
  const location = useLocation();
  const isPolicyPage = ['/privacy-policy', '/terms-of-service', '/location-terms'].includes(location.pathname);

  useEffect(() => {
    if (isPolicyPage) {
      hideChannelButton();
    } else {
      showChannelButton();
    }
    
    return () => {
      showChannelButton();
    };
  }, [isPolicyPage, location.pathname]);

  if (isPolicyPage) {
    return (
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/location-terms" element={<LocationTerms />} />
      </Routes>
    );
  }

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Main;
