import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from './styles/theme'
import GlobalStyle from './styles/GlobalStyle'
import Main from './pages/Main/Main'
import { initChannelTalk } from './utils/channelTalk'
import ScrollToTop from './components/common/ScrollToTop'

function App() {
  useEffect(() => {
    initChannelTalk();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <ScrollToTop />
        <Main />
      </Router>
    </ThemeProvider>
  )
}

export default App
