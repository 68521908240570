import { boot } from '@channel.io/channel-web-sdk-loader';

export const CHANNEL_ID = '6c88d21c-334d-462e-8427-f5bdc298bd04';
export const CHANNEL_SECRET = 'effc0b4b66946d8b484f78a8a0ab35f2';

export const initChannelTalk = async () => {
  try {
    const channelTalk = await boot({
      pluginKey: CHANNEL_ID,
      accessSecret: CHANNEL_SECRET,
      hideChannelButtonOnBoot: false,
      language: 'ko',
      customLauncherSelector: '.channel-talk-button',
    });
    return channelTalk;
  } catch (error) {
    console.error('채널톡 로드 중 오류 발생:', error);
    return null;
  }
};

// 채널톡 버튼 보이기/숨기기
export const showChannelButton = () => {
  if (window.ChannelIO) {
    window.ChannelIO('showChannelButton');
  }
};

export const hideChannelButton = () => {
  if (window.ChannelIO) {
    window.ChannelIO('hideChannelButton');
  }
};

// 채널톡 창 열기
export const openChannelTalk = () => {
  if (window.ChannelIO) {
    window.ChannelIO('openChat');
  }
}; 