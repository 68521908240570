import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { hideChannelButton } from '../../../utils/channelTalk';

const TermsOfServiceContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 0;
  background-color: #ffffff;
  overflow-y: auto;
`;

const ContentContainer = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px;
  background-color: #ffffff;
  
  ${props => props.theme.media.mobile} {
    padding: 20px 16px;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
  
  ${props => props.theme.media.mobile} {
    font-size: 24px;
    margin-bottom: 30px;
  }
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 1.6;
  word-break: keep-all;
  
  h1, h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 40px 0 20px;
    
    ${props => props.theme.media.mobile} {
      font-size: 20px;
      margin: 30px 0 15px;
    }
  }
  
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 30px 0 15px;
    
    ${props => props.theme.media.mobile} {
      font-size: 18px;
      margin: 25px 0 12px;
    }
  }
  
  p {
    margin-bottom: 15px;
  }
  
  ul, ol {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 8px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px 12px;
    text-align: left;
  }
  
  th {
    background-color: #f5f5f5;
  }
`;

const markdownContent = `
## **제 1장**

### **제1조(목적)**

이 약관은 주식회사 '펴다'(이하 '회사'라고 합니다.) 에서 제공하는 정보 통신 서비스 (우산 대여·반납 서비스 등을 포함합니다.) 및 제반 서비스를 이용함에 있어 '회원'과 '회사' 간의 권리, 의무 및 책임사항, 서비스 이용조건 등 기본적인 사항을 규정하는 것을 목적으로 합니다.

### **제2조(이용약관의 공지)**

① '펴다'서비스 이용을 위한 회원가입 시 이용자는 본 약관의 내용을 이해하고 동의한 것으로 간주합니다.

② '회사'는 관련 법령에 위반되지 않는 범위 내에서 본 약관을 개정할 수 있으며, 약관 변경 시 시행일로부터 최소 7일 이상의 고객 고지기간을 둡니다. 변경된 약관은 고지기간 중 공지한 시행일로부터 효력을 발생합니다.

➂ 이용자가 상기 고지기간 중 변경된 약관에 대한 명시적인 거절의 의사표시를 하지 않았을 때에는 본 약관 변경에 동의한 것으로 간주됩니다. 개정약관에 동의하지 않을 경우, 이용자는 약관 제12조에 따라 회원가입을 해지(탈퇴)할 수 있습니다.

### **제3조(약관 외 준칙)**

본 약관에 정의되지 않은 사항은 관계법령이 있는 경우 그 규정을 따르며, 그렇지 않은 경우 일반적인 관례에 따릅니다.

### **제4조(용어의 정의)**

1. 회사: '우산' 산업과 관련된 경제활동을 영위하는 자로서 '공유우산 플랫폼 서비스'를 제공하는 자로서 본 약관에서는 '주식회사 펴다'를 말합니다.
2. 공유우산 어플·플랫폼: '어플 혹은 플랫폼'이란 주식회사 '펴다'가 우산 대여·반납 서비스를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 설정한 가상의 영업장을 말하며, 아울러 어플을 운영하는 사업자를 일컫습니다.
3. 회원: 본 약관에 동의하여 '회사'와 이용계약을 체결하고 '회사'가 공지하는 정보를 수령하며 '회사'가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
4. 비회원: 회원에 가입하지 않아 '회사'가 제공하는 서비스를 이용할 수 없는 자를 말합니다. '비회원'의 경우'회사'의 서비스를 이용하는 것이 아니므로 본 약관을 적용하지 아니합니다.
5. 이용자: '회사'의 이용약관에 동의하며, '회사'의 회원으로 가입한 자를 말합니다.
6. 계정: '회사'가 '회원' 가입 시 '회원'의 동일성, 로그인, 정보변경, 결제정보 등을 관리할 수 있도록 '회원'정보의 식별 목적으로 발급하는 것으로서 문자, 숫자, 특수문자 등의 조합으로 이루어진 정보를 말합니다.
7. 유료서비스 : '회사'가 제공하는 서비스 중 '회원'이 '회사'에 일정 금액을 지불하거나, '회원'이 '회사' 또는 제3자와의 거래 내지 약정 조건을 수락하는 경우에 이용할 수 있는 '회사'의 서비스를 의미합니다. 단, '회사' 또는 위 제3자와 연결(링크)된 유료 콘텐츠 제공업체 및 '회사'와 계약을 통하여 입점한 제휴업체의 서비스를 이용하는 것은 '회사'의 '유료서비스' 이용 행위로 보지 않으며, 본 약관을 적용하지 아니합니다.
8. 비밀번호: '회사'의 시스템 상에 등록된 개인정보를 보호하기 위해 설정된 개인이 정의한 영문, 숫자 및 특수기호 조합을 말합니다.
9. 대여비밀번호: 대여 시 이용자 확인을 위해 설정하는 비밀번호를 말합니다.
10. 대여소: 무인으로 우산을 대여 및 반납할 수 있도록 하는 시설을 말합니다.
11. 대여: '펴다의 어플·플랫폼'에서 우산을 선택 후, '회사'가 지정한 방법에 따라 잠금장치를 해제한 후, '어플·플랫폼'에서 '대여 완료'라는 안내가 된 경우를 말합니다.
12. 반납: '우산'을 '대여소'에 거치한 후, '회사'가 지정한 방법에 따라 잠금장치를 잠금한 후, '어플·플랫폼'에서 '반납 완료'라는 안내가 된 경우를 말합니다.
13. 스마트폰: '이용자'가 앱 프로그램을 이용하여 정보를 수용하고, 제공받고자 하는 서비스를 이용할 수 있도록 하는 개인용 휴대전화기를 말합니다.
14. 서비스 이용: '이용자'가 '회사'의 서비스를 통해 우산을 대여하고 반납하는 일련의 행위를 말합니다.
15. 기본요금: 정기권 또는 1일 권 구매 시 지정된 요금을 말합니다.
16. 추가요금: 1시간마다 지불하게 되는 '회사'에서 지정한 요금을 말합니다.
17. 분실·파손 배상금: 과실로'우산'의 분실 또는 파손이 일어났을 시 18,000원을 추가로 징수함. 고의로 '우산'의 분실 또는 파손이 일어났을 시 형법 제 366조에 따라 3년 이하의 징역 또는 1,000만 원 이하의 벌금에 처할 수 있음.

## **제 2장**

### **제5조 (회원신청)**

① 국민은 누구나 '펴다'의 회원이 될 수 있습니다.

② '회사'의 회원이 되기를 원하는 경우, '펴다 어플·플랫폼'에서 회원으로 가입해야 합니다.

③ 회원가입 시 회원은 다음 각 호의 필수 기재사항을 기입하여야 합니다. (단, 간편 로그인은 간편 로그인 절차에 따릅니다.)

1. 이름
2. 주소
3. 휴대전화번호
4. 아이디 ‧ 비밀번호
5. 결제 정보

상기 사항은 반드시 본인의 정보를 기입하여야 하며, 변경사항 발생 시 갱신하여야 합니다. 허위 사항 기재, 미갱신 시 불이익을 받으실 수 있습니다.

만 14세 미만의 경우 회원가입 시 법정대리인의 동의가 필요하며, 법정대리인 개인정보를 추가로 수집합니다.

### **제6조 (회원가입 유보 및 거부)**

① '회사'에서는 다음과 같은 경우에는 회원가입 승인을 보류할 수 있습니다.

1. 기술적인 문제로 정상적인 서비스 이용이 불가할 경우
2. 효율적인 우산대여 서비스 운영을 위해 필요하다고 인정되는 경우

② '회사'는 다음 각 호에 해당하는 회원가입 요청에 대하여는 거부할 수 있습니다.

1. 다른 사람의 명의를 도용하여 가입신청을 하였을 경우
2. 가입 시 기재사항을 허위로 작성하였을 경우
3. 회원자격이 본 약관에 의거하여 해지된 자

### **제7조 (회원 개인정보 변경)**

① 회원은 언제든지 '펴다 어플·플랫폼'에서 자신의 개인정보를 조회하거나 수정할 수 있습니다. 다만, 서비스 관리를 위해 '회사'가 필요하다고 판단한 개인정보는 수정이 불가능합니다.

② 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 이메일 등 기타 방법으로 '회사'에 그 변경사항을 알려야 합니다.

③ 제2항의 변경사항을 '회사'에 알리지 않아 발생한 불이익에 대하여 주식회사 '펴다'는 책임지지 않습니다.

## **제 3장**

### **제8조 (약관 등의 명시와 설명 및 개정)**

① '회사'가 제공하는 내용은 다음과 같습니다.

1. 우산 대여 서비스
2. 우산 이용요금 결제를 위한 결제서비스

② '회사'는 제1항의 각 호의 서비스 이외에도 추가적인 서비스를 개발하여 회원에게 제공할 수 있습니다.

③ 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.

④ '회사'는 시스템 운영에 필요한 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우, '우산'설비의 유지 및 보수로 인해 서비스 제공이 불가능한 경우, 국가비상사태, 서비스 시설의 장애, 과도한 서비스 이용으로 인한 시스템 마비 등 비정상적인 상황 또는 상기 비상 상황에 대비(복구)하기 위하여 서비스 제공이 불가능할 경우, 서비스 제공을 일시적으로 중단할 수 있습니다. 이 경우 '회사'는 회원에게 중단사실을 공지하여야 합니다. 다만, '회사'가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 공지를 할 수 있습니다.

⑤ 또한, 긴급 작업 및 교체 등으로 인한 '대여소'의 임시폐쇄, 또는 이와 유사한 사유로, 서비스 이용이 일시적, 국소적으로 제한될 수 있습니다.

⑥ '회사'는 서비스 제공에 필요한 경우 점검을 실시할 수 있으며, 점검시간은 최소 7일 전 공지해야 합니다.

### **제9조 (서비스 이용시 주의사항)**

① 이용자는 '회사'가 제공하는 서비스를 통하여 우산 사용에 대한 이용요금을 지불하고 우산을 이용해야 합니다.

② 서비스 이용 시 고의 내지 과실로 인하여 본조 제2항에 규정된 본 약관 내용 및 관련 법령, 자치법규를 위반하는 회원은 본 약관상 규정된 불이익 내지 관련 법령 및 자치법규상 규정된 불이익을 받게 되며, 주식회사 '펴다'에서는 이에 대하여 어떠한 책임도 부담하지 않습니다.

③ '회사'는 '우산'이 최대 초과 대여시간 안에 반납되지 않을 시 분실(도난)로 간주하여 분실(도난) 신고할 수 있습니다.

### **제10조 (서비스 이용요금)**

① 주식회사 '펴다'의 서비스 이용은 유료이며, '회사'에서 정한 이용요금을 징수합니다.

② '회사'는 서비스에 적용되는 이용요금‧추가요금‧환불정보를 어플 및 플랫폼의 이용정책 등을 통하여 회원에게 공지합니다.

③ 경우에 따라 이용요금 산정 기준은 변경될 수 있으며, 이에 대한 '회사'의 적절한 공지 및 안내에도 불구하고 회원이 이를 숙지하지 못하여 입게 되는 손해에 대하여 '회사'는 어떠한 책임도 부담하지 않습니다.

④ 기본 이용시간을 초과할 경우, 이용권 구매 시 사용된 결제수단 또는 사용자가 사전에 지정한 결제수단을 통하여 추가요금이 청구됩니다.

⑤ 주식회사 '펴다'서비스 이용 도중 이용자 자신의 위반행위('회사' 서비스 이용약관 위반)로 인하여 부과 받은 벌금‧추가요금은 이용자 본인이 직접 부담하여야 합니다.

### **제11조 (회원의 계정 관리)**

① 회원은 자신의 계정정보를 타인에게 양도, 대여, 이용을 허락할 수 없으며, 계정정보를 공유하는 등의 행위를 할 수 없습니다.

② 회원이 자신의 계정정보를 소홀히 관리하거나 제3자에게 노출하여 발생하는 모든 책임은 해당 회원에게 있습니다.

③ 회원은 자신의 계정정보가 도용되거나 제3자가 무단으로 사용하고 있음을 인지한 경우, 즉시 '회사'에 통지하고 '회사'의 안내에 따라야 합니다.

### **제12조 (회원자격 정지 및 해지)**

① '회사'는 아래의 각 호에 해당하는 경우, 회원자격을 정지, 해지할 수 있습니다.

1. 본 약관에서 명시된 회원가입 거부사유에 해당하는 사실이 추후 확인된 경우
2. 약관 제16조 등에 명시된 이용자의 의무와 책임관련 내용을 준수하지 않는 경우
3. 기타 본 약관 및 관련 법령에 위반되거나, 사회질서에 반하는 행위를 하여 회원자격을 유지하는 것이 부적절하다고 판단되는 경우

② 회원이 본인의 회원자격을 정지 또는 해지(탈퇴)하고자 할 경우, 주식회사 '펴다'의 '어플·플랫폼·콜센터'에 연락하여 회원자격에 대한 정지 및 해지 신청을 하여야 합니다.

③ '회사'는 회원자격 정지·해지 또는 탈퇴처리 시, '회사'에서 지정한 공제금액을 제외한 기본요금을 환불 처리합니다. 잔여 이용권이 존재하는 회원의 경우 환불 및 연장 규정에 따라 환불 처리합니다.

④ 회원은 ①항에 따른 서비스 이용정지 기타 서비스 이용과 관련된 이용제한에 대해 '회사'가 정한 절차에 따라 이의신청을 할 수 있으며, '회사'는 회원의 이의신청이 정당하다고 판단되는 경우 즉시 서비스 이용을 재개합니다.

⑤ '회사' 서비스와 관련하여 발생한 미납 경비, 손해배상채무 등 회원의 '회사'에 대한 채무가 전부 정산될 때 까지 해당 회원의 서비스 사용은 중지되며, 해당 회원이 그 채무를 상당 기간 계속하여 변제하지 않을 경우에는 '회사'는 해당 회원의 회원자격을 박탈할 수 있습니다. 또한 서비스 사용중지 또는 회원자격 박탈에 대한 판단 기준은 '회사'의 본 약관 및 이용정책에 근거합니다.

⑥ 회원이 사망한 경우 회원 사망일에 회원자격이 상실되며, 제3자가 사망자의 회원자격을 도용하여 행한 모든 행위는 유효한 것으로 인정되지 않습니다.

⑦ 회원 탈퇴 시 회원이 보유한 이용권은 자동으로 소멸됩니다.

## **제 4장**

### **제13조 (요금 규정)**

① 기본 이용료는 300원이며 1시간 마다 70원씩 요금이 누적되어 이용자에게 부과됩니다.

② 이용자 본인의 과실로 인한 우산 대여‧반납 서비스에 따른 추가 요금 발생 시 전액 이용자가 부담합니다.

### **제14조(청약철회 등)**

① 환불 처리 시 소요되는 결제 수수료 등의 비용은 회원이 원하여 탈퇴할 경우 회원이, '회사' 서비스 하자로 인한 경우에는 '회사'에서 부담합니다.

② '회사'의 상품에 따른 서비스를 결제 후 1분 미만의 시간이 경과했을 경우에는 구매회원의 청약철회 및 그에 대한 전액 환불이 가능합니다. 1분 초과의 시간이 경과했을 경우에는「전자상거래 등에서의 소비자보호에 관한 법률」 제17조제2항 본문 및 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조에 의해 원칙적으로 구매회원의 청약철회가 제한됩니다.

③ 이용자는 '우산'을 고른 이후 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.

1. 이용자에게 책임 있는 사유로 재화(우산) 등이 멸실 또는 훼손된 경우
2. 이용자의 사용 또는 일부 소비에 의하여 재화(우산) 등의 가치가 현저히 감소한 경우

④'회사'가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.

⑤ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.

### **제15조(청약철회 등의 효과)**

① '회사'는 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 '회사'가 이용자에게 재화등의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의3에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.

② '회사'는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.

③ '회사'는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 '회사'가 부담합니다.

### **제16조 ('회사'의 의무)**

① '회사'는 회원 가입 시 입력된 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 다만, 개인정보보호법 제17조 및 제18조에 해당하는 경우에는 본인의 승낙 없이 제3자에게 제공 될 수 있습니다.

② '회사'는 서비스 내용의 변경 또는 추가 사항이 있을 경우 홈페이지‧ 공지사항을 통하여 공지하도록 합니다.

③ 회원 가입 시 입력된 회원의 개인정보는 회원탈퇴와 동시에 삭제됩니다. 다만, 관련 법령에 따라 보존할 필요가 있는 경우에는 해당 법령에서 정한 기간 동안 보관합니다.

④ '회사'는 서비스 이용과 관련하여 회원에게 발생한 손해에 대해 책임을 집니다. 다만, '회사'의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.

### **제17조 ('이용자'의 의무)**

① 이용자는 '회사'의 서비스 이용에 필요한 휴대전화번호, 대여비밀번호, 결제정보 등을 안전하게 기밀로 유지해야 합니다. 이용자 자신의 휴대전화번호, 대여비밀번호, 결제정보 등의 관리를 소홀히 하여 발생하는 모든 책임은 이용자 본인에게 있습니다.

② 서비스 이용과 관련하여 이용자가 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 이용자가 부담하여야 합니다.

③ 이용자는 서비스를 이용하기 전에 반드시 서비스 약관을 확인해야 합니다.

약관을 확인하지 않고 서비스를 이용함으로써 발생하는 모든 손실과 손해에 대한 책임은 이용자 본인에게 있습니다.

④ 이용자는 서비스 이용 시 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제수단을 임의로 사용해서는 안 됩니다. 타인의 결제수단을 임의로 사용함으로써 발생하는 손실 및 손해에 대한 모든 책임은 이용자에게 있습니다.

⑤ 이용자는 '회사'의 서비스를 안전하게 제공할 수 있도록 '회사'에 협조하여야 하며, '회사'가 이용자의 본 약관 및 이용정책 위반 행위를 발견하여 이용자에게 해당 위반행위에 대하여 소명을 요청할 경우 이용자는 '회사'의 요청에 적극 응하여야 합니다.

⑥ 서비스의 이용은 반드시 이용자 본인의 이용을 전제로 해야 합니다.

⑦ 이용자는 '회사'의 서비스를 이용할 때, 다음 각 호의 행위를 하지 않아야 합니다.

1. 회원이 서비스 가입 또는 회원정보 변경 시 허위 내용을 등록하는 행위
2. 회원이 서비스 가입 또는 사용을 위해 타인의 정보를 도용하는 행위
3. 다른 회원의 개인정보 및 계정정보를 수집하는 행위
4. 회원의 계정을 타인이 사용하도록 권한을 부여하는 행위, 이용권한을 양도 및 이전하는 행위, 공동으로 이용하는 행위
5. '우산'을 개인 용도로 개조, 수리, 변형하는 행위 또는 '우산'에 부착된 각종 장비를 임의로 제거하거나 다른 용도로 사용하는 행위
6. '우산'을 상거래에 이용함으로써 개인적인 이득을 취하는 행위
7. 서비스를 이용하면서 얻은 정보를 운영자인 주식회사 '펴다'의 승낙 없이 회원의 이용 이외 목적으로 복제 또는 제3자에게 제공하는 행위
8. '회사'의 서비스를 이용하는 다른 이용자 또는 제3자의 권리를 침해하는 행위
9. 올바른 네트워크의 작동을 손상시킬 수 있는 행위
10. 호환되지 않거나 권한을 부여 받지 않은 장치로 서비스나 앱을 사용하는 행위
11. '회사'의 서비스 운영을 고의적으로 방해하는 행위
12. '회사'가 제공하는 서비스와 '우산'을 부정한 방법으로 정해진 요금을 납부하지 않고 사용하는 행위
13. '회사' 앱(웹)상 게재된 매뉴얼 및 이용안내 등에 저촉되는 '우산' 사용으로 정상적인 대여, 반납을 저해하는 행위
14. 주식회사 '펴다'의 종사자의 인권을 부당하게 침해한 경우(폭언, 폭행, 성희롱, 업무방해 등)

⑧ 이용자는 서비스 이용 도중 다음 각 호와 같은 상황이 발생할 경우, '회사' 콜센터로 즉시 통보하여야 합니다.

1. 대여 도중에 발생한 '우산'의 파손, 손실 및 분실
2. '펴다 어플'이 설치된 스마트폰이나 회원카드의 분실 및 도난

⑨ 이용자는 관계법, 본 약관의 규정, 이용안내 및 서비스 관련 주의사항, '회사' 공지사항 등을 준수하여야 합니다.

### **제 18조 (회원에 대한 공지)**

① 회원에 대한 공지는 '회사' 앱, 홈페이지, 회원이 제공한 이메일, SMS, 또는 알림톡 등으로 할 수 있습니다.

② 불특정 다수 이용자에 대한 공지는 '펴다'어플·플랫폼'의 팝업을 통해 게시하거나 SMS, 알림톡 등으로 개별 공지에 갈음할 수 있습니다.

③ '회사'가 상기와 같이 정상적으로 공지하였음에도 이용자가 공지된 내용을 확인하지 않음으로써 회원에게 발생한 불이익에 대해 '회사'는 책임을 지지 않습니다.

### **제19조 (이의신청 및 손해배상)**

① 이용자의 실수 및 부주의로 인해 발생한 문제에 대해서는 '회사' 관계 기관과 위탁 운영기관은 책임을 지지 아니합니다. 단, 공단 귀책사항에 의한 '회사' 이용 불가로 발생한 손해의 경우, 보상은 장애로 인한 미사용 기간에 대한 연장에 한하며, 보상기준은 '펴다 어플·플랫폼'에 게시한 환불정책에 따릅니다.

② 8조 4항에 규정된 상기 비상 상황에 대비(복구)하기 위하여 서비스 제공이 불가능할 경우, 이로 인해 회원에게 발생한 손해에 대하여 책임을 부담하지 않습니다.

③ 이용자가 '회사'에서 제공되는 우산 이용 중 우산의 결함으로 인하여 사고를 당하였을 경우 '회사'에서 가입한 '손해배상 보험' 약관에 의하여 배상을 받을 수 있습니다.

※ 단, 우산의 결함 및 관리상 하자의 입증 책임은 이용자 본인에게 있으므로, 사고가 발생할 경우 반드시 증빙자료를 제출해야 합니다.

※ 증빙자료 : 파손된 우산 사진 또는 동영상, 인적·물적 피해를 증명할 수 있는 사진 등

### **제20조 (게시물의 관리)**

① 고장/분실제보에 게시된 회원의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법 등 관련 법령에 위반되는 내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, '회사'는 관련 법령에 따라 적절한 조치를 취하여야 합니다.

② '회사'는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 정책 및 관련 법령에 위반되는 경우에는 관련 법령에 따라 해당 게시물에 대한 임시조치 등을 취할 수 있습니다.

### **제21조 (게시물의 저작권)**

① 회원이 서비스 내에 게시판 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.

② '회사'는 회원의 게시물을 이용하고자 하는 경우 전화‧팩스‧전자우편 등을 통해 사전에 회원의 동의를 얻어야 합니다.

## 부칙

이 약관의 효력은 2023년 6월 1일부터 발생합니다.
`;

function TermsOfService() {
  useEffect(() => {
    // 브라우저에서 실행될 때만 실행
    if (typeof window !== 'undefined') {
      // 문서 제목 설정
      document.title = '서비스 이용약관';
      
      // body 스타일 설정
      document.body.style.margin = '0';
      document.body.style.padding = '0';
      document.body.style.overflow = 'auto';
      document.body.style.height = '100vh';
      
      // 채널톡 버튼 숨기기
      hideChannelButton();
    }
    
    return () => {
      // 컴포넌트 언마운트 시 필요한 정리 작업이 있다면 여기에 추가
    };
  }, []);

  return (
    <TermsOfServiceContainer>
      <ContentContainer>
        <Title>서비스 이용약관</Title>
        <Content>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {markdownContent}
          </ReactMarkdown>
        </Content>
      </ContentContainer>
    </TermsOfServiceContainer>
  );
}

export default TermsOfService; 