import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { hideChannelButton } from '../../../utils/channelTalk';

const PrivacyPolicyContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 0;
  background-color: #ffffff;
  overflow-y: auto;
`;

const ContentContainer = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px;
  background-color: #ffffff;
  
  ${props => props.theme.media.mobile} {
    padding: 20px 16px;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
  
  ${props => props.theme.media.mobile} {
    font-size: 24px;
    margin-bottom: 30px;
  }
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 1.6;
  word-break: keep-all;
  
  h1, h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 40px 0 20px;
    
    ${props => props.theme.media.mobile} {
      font-size: 20px;
      margin: 30px 0 15px;
    }
  }
  
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 30px 0 15px;
    
    ${props => props.theme.media.mobile} {
      font-size: 18px;
      margin: 25px 0 12px;
    }
  }
  
  p {
    margin-bottom: 15px;
  }
  
  ul, ol {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 8px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px 12px;
    text-align: left;
  }
  
  th {
    background-color: #f5f5f5;
  }
`;

const markdownContent = `
주식회사 펴다(이하 "회사"라고 합니다.)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 회원의 권익 보호에 최선을 다하고 있습니다. 회사는 개인정보처리방침을 통하여 회원이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 회사는 개인정보취급방침을 개정하는 경우 앱 공지사항(또는 개별공지)을 통하여 공지할 것입니다. 

### **제1조 (개인정보의 수집 및 이용목적)**

회사는 개인정보를 회원관리, 서비스 개발·제공 및 향상, 안전한 모바일 이용환경 등 다음의 목적을 위하여 수집하고 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 것입니다.

1. 회원 가입 의사 및 회원 탈퇴 의사 확인
2. 회원 가입 신청 휴대전화번호 명의인과 회원 가입 신청인의 동일성 확인
3. 회원에게 약관 상 회원 가입 거절 사유가 있는지 여부 등 회원 가입 신청에 대한 회사의 승낙 여부 판단
4. 회원에 대한 서비스 제공
5. 서비스를 이용하는 회원 식별
6. 계정도용 및 부정거래 방지, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재
7. 회원의 법령 및 약관 위반 여부 모니터링, 회원의 법령 및 약관 위반에 대한 회사의 조치, 회원에 대한 쿠폰 및 이용권 지급, 소멸, 회원의 저작물 식별 등 회원 관리
8. 약관 상 필요한 회사의 회원에 대한 통지, 정보 전달
9. 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축
10. 연령, 성별, 서비스 이용 기록, 모바일 어플리케이션 "PYEODA(펴다)" 접속 빈도 및 통계 분석 등을 통한 서비스 개선, 향상 및 신규 서비스 개발
11. 회사와 회원 사이의 분쟁 발생 시 분쟁 해결, 분쟁 조정, 회원의 문의사항 또는 불만에 대한 처리
12. 서비스 이용요금 과금, 과금 불능(회원의 등록카드가 기간경과, 계약종료, 유효성, 잔액부족, 한도초과, 회원의 허위 등록, 오등록 등)에 대한 대응, 과금에 대한 회원의 이의제기에 대한 대응
13. 사고발생시 원인 규명 및 처리를 위한 수집 및 이용
14. 법령에 정해진 절차와 방법에 따라 수사기관의 요구
15. 개별 동의한 회원에 한해 이벤트 정보 및 참여기회 제공, 광고성 제공 정보 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.

### **제2조 (수집∙이용하는 개인정보의 항목)**

회사가 수집∙이용하는 개인정보 및 그 수집방법은 아래와 같습니다.

1. 회원 가입 신청 시에 회사는, 회원이 직접 모바일 어플리케이션 "PYEODA(펴다)" 통하여 전송제공하는 방식으로 다음의 항목을 필수항목으로 수집하고 있습니다.
    - 1) 성명, 생년월일, 휴대전화번호
    - 2) 내국인정보, 식별정보(CI), 중복확인가입정보(DI)
2. 회원 가입 이후 회사는 회원이 직접 모바일 어플리케이션 "PYEODA(펴다)"에 기입하는 방식으로 다음의 항목을 선택항목으로 수집할 수 있습니다.
    - 1) 별명, 이메일주소, 성별, 연령(생년월일)
3. 서비스 이용과정이나 사업 처리 과정에서 다음과 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
    - 1) IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기 정보, 채팅 기록, 앱 설치정보, 네트워크 위치정보 등
4. 서비스 이용 과정에서 회사는, 회원이 모바일 어플리케이션 "PYEODA(펴다)"을 통하여 위치정보 이용약관에 동의한 경우에만 개인형 이동수단 단말기를 통하여 직접 위치정보를 수집하거나, 개인위치정보사업자(휴대전화 제조사, 통신사, 포털사 등)에게 회원 가입 명의 휴대전화가 특정한 시간에 존재하거나 존재하였던 장소에 관한 정보(이하 "개인위치정보"라고 합니다.)의 제공을 요청하여 수령합니다. 개인위치정보의 수집, 이용, 제공에 관한 사항은 위치정보 이용약관에 기재되어 있습니다.
5. 서비스 이용과정에서 다음과 같은 결제정보들이 수집될 수 있습니다.
    - 1) 신용카드/간편결제 결제 시: 카드사명, 카드번호 등
    - 2) 환불 시 : 은행명, 계좌번호 등
6. 회사는 회원의 결제수단 등 서비스 운영상 필요하다고 판단하는 회원의 정보에 대하여 정기적으로 유효성을 검사하거나 확인할 수 있습니다.

### **제3조 (개인정보의 보유 및 이용 기간)**

회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

1. 관련 법령에 의한 정보 보유
   - 계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
   - 대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
   - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래 등에서의 소비자보호에 관한 법률)
   - 표시/광고에 관한 기록: 6개월 (전자상거래 등에서의 소비자보호에 관한 법률)
   - 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류: 5년 (국세기본법, 법인세법)
   - 전자금융거래에 관한 기록: 5년 (전자금융거래법)
   - 서비스 방문 기록: 3개월 (통신비밀보호법)

2. 회원이 개인위치정보의 보유에 별도로 동의한 경우에는 회원이 동의한 때로부터 최대 1년간 이를 보유할 수 있습니다.

### **제4조 (동의의 거부권 및 거부 시의 불이익 고지)**

회원은 개인정보 수집ㆍ이용에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 및 이행 등을 위해 필요한 최소한의 개인정보 수집ㆍ이용에 관한 동의를 거부하는 경우에는 서비스 이용이 불가하고, 철회 이후 임대계약에 따른 운행건에 대한 사고 처리• 보상•환불 등의 업무가 지연되는 등의 불이익이 있을 수 있습니다.

또한, 마케팅 활동 및 홍보를 위한 개인정보 수집ㆍ이용 및/또는 선택적 수집ㆍ이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품ㆍ판촉물 제공, 제휴서비스 이용, 할인 혜택 적용 및 포인트 적립 불가 등의 불이익이 있을 수 있습니다.

### **제5조 (개인정보의 파기 절차 및 방법)**

회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만, 법률에 따라 보유하여야 하거나 회원이 개인위치정보의 보유에 별도로 동의한 경우에는 회원이 동의한 때로부터 최대 1년간 이를 보유할 수 있습니다.

회사의 개인정보 파기 방법은 다음과 같습니다.

1. 전자 파일 : 복원이 불가능한 방법으로 영구삭제
2. 종이 문서 : 파쇄 또는 소각

### **제6조 (회원 및 법정대리인의 권리와 그 행사방법)**

1. 회원 및 법정대리인은 회사에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할 수 있으며, 등록되어 있는 본인 혹은 대리자의 개인정보에 대한 열람, 정정, 삭제 및 처리 정지를 요청할 수 있습니다.
    - 1) 개인 정보 열람 요구
    - 2) 오류 등이 있을 경우 정정 요구
    - 3) 삭제요구
    - 4) 처리정지 요구
2. 전 항에 따른 권리 행사는 서면, 전화 또는 이메일로 하실 수 있으며 회사는 이에 대해 지체 없이 조치하고 처리결과를 서면 또는 이메일, 문자 전송의 방법으로 통지합니다.
3. 회사가 회원의 개인위치정보를 제3자에게 제공하는 서비스를 하고자 하는 경우에는 회원에게 미리 동의를 구하며, 이 경우 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시 통지합니다.
4. 회원이 개인정보의 오류에 대한 정정을 요청한 경우, 회사는 당해 정정을 완료하기 전까지 당해 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 제공한 경우에는 정정 처리 결과를 제3자에게 지체 없이 통지합니다.
5. 회원이 개인정보의 삭제를 요청한 경우 제3조의 개인정보의 보유 및 이용 기간과 제5조의 개인정보의 파기 절차 및 방법에 명시된 바에 따릅니다.
6. 본 조에 따른 권리 행사는 회원 또는 위임을 받은 자(대리인)을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출 하셔야 합니다.

### **제7조 (아동의 개인정보 보호)**

1. 회사는 만 14세 미만 아동의 개인정보 수집 시 법정대리인의 동의를 얻어야 합니다. 만 14세 미만 아동이 법정대리인의 동의 없이 회원 가입을 시도하는 경우, 회사는 해당 아동의 회원 가입을 거부할 수 있습니다.

2. 회사는 아래의 경우에 해당하는 자(이하 "8세 이하의 아동 등"이라고 합니다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 수집, 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.
    1. 8세 이하의 아동
    2. 피성년후견인
    3. 장애인복지법 제2조 제2항 제2호의 규정에 따른 정신적 장애를 가진 자로서 장애인 고용촉진 및 직업재활법 제2조 제2호의 규정에 따라 중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 따라 장애인등록을 한 자에 한합니다)

3. 전항의 규정에 따른 8세 이하 아동 등의 보호의무자는 해당 아동을 사실상 보호하는 자로서 다음 각 호에 해당하는 자를 말합니다.
    1. 8세 이하의 아동의 법정대리인 또는 보호시설에 있는 미성년자의 후견 직무에 관한 법률 제3조의 규정에 따른 후견인
    2. 피성년후견인의 법정대리인
    3. 본 조 제2항 제3호의 자의 법정대리인 또는 장애인복지법 제58조 제1항 제1호의 규정에 따른 장애인생활시설(국가 또는 지방자치단체가 설치∙운영하는 시설에 한합니다)의 장, 정신보건법 제3조 제4호의 규정에 따른 정신질환자 사회복귀시설(국가 또는 지방자치단체가 설치∙운영하는 시설에 한합니다)의 장, 동법 동조 제5호의 규정에 따른 정신요양시설의 장

4. 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 수집, 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다. 해당 서면은 아래 각 호의 사항을 기재하여 기명날인 또는 서명한 서면동의서에 보호의무자임을 증명하는 내용을 담습니다.
    1. 8세 이하의 아동 등의 성명, 주소 및 생년월일
    2. 보호의무자의 성명, 주소 및 연락처
    3. 개인위치정보 수집, 이용 또는 제공의 목적이 8세 이하의 아동 등의 생명 또는 신체의 보호에 한정된다는 사실
    4. 동의의 연월일

5. 보호의무자는 8세 이하의 아동 등의 개인위치정보 수집, 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.

### **제8조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)**

회사는 회원의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다. 쿠키란 회사의 모바일 어플리케이션을 운영하는데 이용되는 서버가 회원의 휴대전화에 보내는 아주 작은 텍스트 파일로서 회원의 휴대전화에 저장됩니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.

1. 쿠키 등 사용 목적: 회원의 접속 빈도나 접속 시간 등을 분석, 회원의 이용 시간, 이동 경로 파악 및 추적, 모바일 어플리케이션 항목 확인 횟수 파악 등을 통한 개인 맞춤 서비스를 제공하기 위해 사용합니다.
2. 쿠키 설정 거부 방법: 회원이 이용하는 브라우저에서 "설정" 내의 "개인정보보호" 항목에서 쿠키를 허용하지 않게 설정
3. 단, 회원이 쿠키 설치를 거부하였을 경우 기술 지원 및 서비스 제공에 어려움이 있을 수 있으며, 이 경우 회사는 책임을 지지 않습니다.
4. 온라인 맞춤형 광고 서비스를 위해서 다음의 항목을 수집 할 수 있습니다.
    - 1) 모바일 앱 사용시 광고 식별자 수집
        
        회사는 이용자의 ADID/IDFA를 수집할 수 있습니다. ADID/IDFA란 모바일 앱 이용자의 광고 식별 값으로서, 사용자의 맞춤 서비스 제공이나 더 나은 환경의 광고를 제공하기 위한 측정을 위해 수집할 수 있습니다.
        
        - ① 거부방법
            
            Android: 설정 → 구글(구글설정) → 광고 → 광고 맞춤설정 선택 해제
            
            iOS: 설정 → 개인정보 보호 → 광고 → 광고 추적 제한
            
    - 2) 온라인 맞춤형 광고 서비스
        
        회사는 다음과 같이 온라인 맞춤형 광고 사업자가 광고식별자 및 행태정보를 수집하도록 허용하고 있습니다.
        
        - ① 행태정보를 수집 및 처리하는 광고 사업자: 구글, Facebook
        - ② 행태정보 수집 방법: 이용자가 당사 앱을 실행할 때 자동 수집 및 전송

### **제9조 (개인위치정보의 수집 및 보유)**

회사가 제공하는 개인위치정보사업 및 위치기반서비스에서 수집하는 개인위치정보 및 개인위치정보의 수집/보유 방법과 그 목적은 아래와 같습니다.

| 서비스명 | 수집방법 | 서비스 내용 및 목적 |
| --- | --- | --- |
| 개인 맞춤 서비스 | 자동 | 주변 우산대여 서비스 제공 장소 정보 제공 |
| 온라인 맞춤형 광고 | 자동 | 주변 상점 정보 제공 / 위치기반 맞춤 광고제공 |
1. 회사는 위치기반서비스를 제공하는 것을 목적으로, 이에 필요한 최소한의 개인위치정보를 보유 및 이용하며, 해당 개인위치정보를 회원 탈퇴 및 서비스 종료 시 지체 없이 파기합니다. 단, 관련 법령에 의한 보관이 필요한 경우 해당 기간만큼 보관합니다.
2. 회사는 타사업자 또는 이용 고객과의 요금정산 및 민원처리를 위해 위치정보 수집·이용·제공사실 확인자료를 자동 기록·보존하며, 해당 자료는 1년간 보관합니다.
3. 기타 개인위치정보의 관리에 대한 자세한 내용은 위치정보 이용약관을 따릅니다.

### **제10조 (개인정보의 제3자 제공)**

회사는 원칙적으로 회원의 개인정보를 제1조(개인정보의 수집 및 이용목적)에서 명시한 범위 내에서 처리하며, 회원의 사전 동의 없이는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. 단, 다음의 경우에는 개인정보를 처리할 수 있습니다.

1. 회원이 사전에 제3자 제공 및 공개에 동의한 경우
2. 법령 등에 의해 제공이 요구되는 경우
3. 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우
4. 개인을 식별하기에 특정할 수 없는 상태로 가공하여 이용하는 경우

회사는 현재 회원의 개인정보를 제3자에게 제공하고 있지 않습니다. 제3자 제공이 필요한 경우 회사는 사전에 회원에게 제3자를 구체적으로 명시하고, 제공되는 개인정보 항목이 무엇인지, 제공받는 자의 개인정보 이용 목적이 무엇인지, 제공받는 자의 개인정보 보유 및 이용 기간 등에 대해 개별적으로 전자우편 또는 서면을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 회원이 동의하지 않는 경우에는 제3자에게 개인정보를 제공하지 않습니다.

### **제11조 (개인정보처리의 위탁)**

회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.

회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부 업체에 위탁하고 있으며, 위탁계약 체결시 개인정보보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 위탁업무의 목적 및 범위, 재위탁 제한, 개인정보 접근 제한 등 안전성 확보 조치, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리·감독합니다.

| 수탁자 | 위탁업무 내용 및 목적 |
| --- | --- |
| 나이스페이먼츠(주) | 전자 결제 서비스 |

위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

### **제12조 (개인정보의 안전성 확보 조치)**

회사는 회원의 개인정보를 안전하게 관리하기 위하여 최선을 다하며, 정보통신망법 및 개인정보보호법에서 요구하는 수준 이상으로 개인정보를 보호하기 위하여 다음과 같은 조치를 취하고 있습니다.

1. 관리적 조치: 개인정보 관리책임자의 지정 등 개인정보보호 조직의 구성, 개인정보 취급자를 최소한으로 제한, 운영에 관한 사항, 개인정보취급자의 교육에 관한 사항을 포함하는 내부관리계획의 수립 및 시행
2. 기술적 조치: 개인정보를 처리할 수 있도록 체계적으로 구성한 데이터베이스시스템에 대한 접근권한의 부여/변경/말소 등에 관한 기준의 수립/시행, 개인정보처리시스템에 대한 침입차단시스템 및 침입탐지시스템의 설치/운영, 개인정보취급자가 개인정보처리시스템에 접속하여 개인정보를 처리한 경우 접속일시, 처리내역 등의 저장 및 이의 확인/감독, 비밀번호의 일방향 암호화 저장, 주민등록번호, 외국인인증번호, 계좌정보, 신용카드정보 등 금융정보의 암호화 저장, 정보통신망을 통하여 이용자의 개인정보 및 인증정보를 송신/수신하는 경우 암호화 보안 통신 이용, 개인정보처리시스템 및 개인정보취급자가 개인정보 처리에 이용하는 정보기기에 컴퓨터바이러스, 스파이웨어 등 악성프로그램의 침투 여부를 항시 점검/치료할 수 있도록 백신소프트웨어 설치 및 주기적 갱신 및 점검

### **제13조 (개인정보보호 업무 관련 담당부서 및 개인정보 관리책임자)**

회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 관리책임자를 지정하고 있습니다. 회원은 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고할 수 있습니다. 회사는 회원들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

- 개인정보 보호책임자
  - 성명: 김형석
  - 직위: 개인정보보호팀장
  - 부서: 개인정보보호팀
  - 연락처: 010-3242-3554
  - 이메일: privacy@pyeoda.com
  - 주소: 서울 강남구 강남대로 526 12층

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

- 개인정보침해신고센터 (https://privacy.kisa.or.kr/국번없이 118)
- 개인정보분쟁조정위원회 (www.kopico.go.kr/국번없이 1833-6972)
- 대검찰청 사이버수사과 (http://www.spo.go.kr/02-3480-2000)
- 경찰청 사이버수사국 (https://cyberbureau.police.go.kr/국번없이 182)

### **제14조 (고지 의무)**

현 개인정보취급방침에 대하여 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 '공지사항'을 통해 고지합니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 회원 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.

### **부칙**

공고일자 : 2023년 5월 1일

시행일자 : 2023년 6월 12일
`;

function PrivacyPolicy() {
  useEffect(() => {
    // 브라우저에서 실행될 때만 실행
    if (typeof window !== 'undefined') {
      // 문서 제목 설정
      document.title = '개인정보처리방침';
      
      // body 스타일 설정
      document.body.style.margin = '0';
      document.body.style.padding = '0';
      document.body.style.overflow = 'auto';
      document.body.style.height = '100vh';
      
      // 채널톡 버튼 숨기기
      hideChannelButton();
    }
    
    return () => {
      // 컴포넌트 언마운트 시 필요한 정리 작업이 있다면 여기에 추가
    };
  }, []);

  return (
    <PrivacyPolicyContainer>
      <ContentContainer>
        <Title>펴다 개인정보처리방침</Title>
        <Content>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {markdownContent}
          </ReactMarkdown>
        </Content>
      </ContentContainer>
    </PrivacyPolicyContainer>
  );
}

export default PrivacyPolicy; 